import Homelayout from '@/views/home' // 主页面结构
// import ReturnPage from '@/views/return-page' // 返回页面结构

export default [{
  path: '/financial-management',
  component: Homelayout,
  children: [
    {
      path: 'new-salary-management',
      name: 'new-salary-management',
      component: () => import('@/views/financial-management/new-salary-management.vue'),
      meta: { title: '工资管理', keepAlive: true, scrollTop: 0, name: 'new-salary-management' }
    },
    {
      path: 'performance-management',
      name: 'financial-performance-management',
      component: () => import('@/views/financial-management/performance-management.vue'),
      meta: { title: '业绩管理', keepAlive: true, scrollTop: 0, name: 'financialPerformanceManagement' }
    },
    {
      path: 'inventory-risk',
      name: 'financial-inventory-risk',
      component: () => import('@/views/financial-management/inventory-risk.vue'),
      meta: { title: '存货风险', keepAlive: true, scrollTop: 0, name: 'financialInventoryRisk' }
    },
    {
      path: 'store-shares',
      name: 'financial-store-shares',
      component: () => import('@/views/financial-management/store-shares.vue'),
      meta: { title: '门店股份', keepAlive: true, scrollTop: 0, name: 'storeShares' }
    },
    {
      path: 'fund-financing',
      name: 'financial-fund-financing',
      component: () => import('@/views/financial-management/fund-financing.vue'),
      meta: { title: '基金理财', keepAlive: true, scrollTop: 0, name: 'fundFinancing' }
    },
    {
      path: 'advances-to-employees',
      name: 'financial-advances-to-employees',
      component: () => import('@/views/financial-management/advances-to-employees.vue'),
      meta: { title: '员工借支', keepAlive: true, scrollTop: 0, name: 'advancesToEmployees' }
    },
    {
      path: 'profit-analysis',
      name: 'financial-profit-analysis',
      component: () => import('@/views/financial-management/profit-analysis.vue'),
      meta: { title: '运营分析', keepAlive: true, scrollTop: 0, name: 'profitAnalysis' }
    },
    {
      path: 'salary-management',
      name: 'financial-salary-management',
      component: () => import('@/views/financial-management/salary-management.vue'),
      meta: { title: '工资管理', keepAlive: true, scrollTop: 0, name: 'salaryManagement' }
    },
    {
      path: 'sales-order-management',
      name: 'financial-sales-order-management',
      component: () => import('@/views/financial-management/sales-order-management.vue'),
      meta: { title: '销售订单', keepAlive: true, scrollTop: 0, name: 'salesOrderManagement' }
    },
    {
      path: 'direct-operated-small-treasury',
      name: 'direct-operated-small-treasury',
      component: () => import('@/views/financial-management/direct-operated-small-treasury.vue'),
      meta: { title: '直营小金库', keepAlive: true, scrollTop: 0, name: 'directOperatedSmallTreasury' }
    },
    {
      path: 'collection-account',
      name: 'financial-collection-account',
      component: () => import('@/views/financial-management/collection-account.vue'),
      meta: { title: '收款账户', keepAlive: true, scrollTop: 0, name: 'financialCollectionAccount' }
    }
  ]
}]
