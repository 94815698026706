import Service from '@/utils/request.js'
import axios from 'axios'
// 登录验证码
export const verification = (params) => Service.get('/user/getVerifyCode')
// 登录版权信息
export const version = (params) => Service.get('/api/version')
// 登录背景
export const loginImgList = (params) => Service.get('/api/getSystemParam?type=LOGIN_IMG&code=phone')
// 用户登录登录
export const userLogin = (params) => Service.post('/user/login', params)
// 更新用户数据
export const updateUserInfo = (params) => Service.post('/user/updateUserInfo', params)
// 忘记密码提交接口
export const sendResetPasswordMail = (params) => Service.get('/user/sendResetPasswordMail?mail=' + params)
// 重置密码
export const resetPassword = (params, t) => {
  if (t) {
    return Service({
      url: '/user/resetPassword',
      method: 'post',
      data: params,
      headers: {
        Authorization: t
      }
    })
  } else {
    return Service.post('/user/resetPassword', params)
  }
}
// 获取用户信息（包括导航数据）
export const getUserInfo = (params) => Service.get('/user/getUserInfo')

// 登录页产品介绍
/**
 * 产品列表
 */
export const goodsList = (params) => axios.post(process.env.VUE_APP_INTERFACE_URL + '/goodsHome/goodsList', params)
/**
 * 产品详情
 */
export const goodsDetail = (params) => axios.post(process.env.VUE_APP_INTERFACE_URL + '/goodsHome/detail', params)
/**
 * 产品分类
 */
export const classType = (params) => axios.post(process.env.VUE_APP_INTERFACE_URL + '/goodsHome/classType', params)

// 公用接口
// 查询时间列表
export const selectDateList = (params) => Service.get('/commont/selectDateList', params)
// 收款方式
export const getSystemParam = (params) => Service.get('/api/getSystemParam?type=RETAIL_PAY_TYPE')
// 查看原图
export const commontGetImg = (params) => Service.post('/commont/getImg', params)
// 查看多张原图
export const commontGetImgList = (params) => Service.post('/commont/getImgList', params)
// 交易科目
export const getSystemParamCLASS = (params) => Service.get('/api/getSystemParam?type=FINANCE_CLASS')
// 操作 公用接口
export const apiGetSystemParam = (params) => Service.get('/api/getSystemParam?type=' + params)

/**
 * 公用公告接口
 */
export const itemHomeWxPage = (params, obj, type) => Service.post('/itemHome/wxPage?groupId=' + params + '&type=' + type, obj)

/**
 * 公用公告详情
 */
export const itemHomeGetDetailDtoByTypeLas = (params) => Service.post('/itemHome/getDetailDtoByTypeLast?groupId=' + params.id + '&type=' + params.type)

/**
 * 二维码列表
 */
export const custBindCustQrCode = (params) => Service.post('/cust/bindCustQrCode', {})
