import ReturnPage from '@/views/return-page' // 返回页面结构
export default [{
  path: '/processSignOff',
  name: 'processSignOff',
  component: ReturnPage,
  children: [
    {
      path: 'salary-grouping/:id/:type?',
      name: 'salary-grouping',
      component: () => import('@/views/processSignOff/components/salary-grouping/salary-grouping.vue'),
      meta: { title: '工资分组' },
      props: true
    },
    {
      path: 'new-apply-for-salary/:id/:type?',
      name: 'new-apply-for-salary',
      component: () => import('@/views/processSignOff/components/new-apply-for-salary/new-apply-for-salary.vue'),
      meta: { title: '新工资申请' },
      props: true
    },
    {
      path: 'cash-collection/:id/:type?',
      name: 'cash-collection',
      component: () => import('@/views/processSignOff/components/cash-collection/cash-collection.vue'),
      meta: { title: '现金回款' },
      props: true
    },
    {
      path: 'group-renewal/:id/:type?',
      name: 'group-renewal',
      component: () => import('@/views/processSignOff/components/group-renewal/group-renewal.vue'),
      meta: { title: '集团续费' },
      props: true
    },
    {
      path: 'exchange-notice/:id/:type?',
      name: 'exchange-notice',
      component: () => import('@/views/processSignOff/components/exchange-notice/exchange-notice.vue'),
      meta: { title: '换货通知' },
      props: true
    },
    {
      path: 'delivery-voucher/:id/:type?',
      name: 'delivery-voucher',
      component: () => import('@/views/processSignOff/components/delivery-voucher/delivery-voucher.vue'),
      meta: { title: '提货送券详情' },
      props: true
    },
    {
      path: 'batch-sale-of-assets/:id/:type?',
      name: 'batch-sale-of-assets',
      component: () => import('@/views/processSignOff/components/batch-sale-of-assets/batch-sale-of-assets.vue'),
      meta: { title: '资产变卖(批量)详情' },
      props: true
    },
    {
      path: 'pre-deposit-import/:id/:type?',
      name: 'pre-deposit-import',
      component: () => import('@/views/processSignOff/components/pre-deposit-import/pre-deposit-import.vue'),
      meta: { title: '预存款导入详情' },
      props: true
    },
    {
      path: 'daily-copy/:id/:type?',
      name: 'daily-copy',
      component: () => import('@/views/processSignOff/components/daily-copy/daily-copy.vue'),
      meta: { title: '日报抄送' },
      props: true
    },
    {
      path: 'inventory-summary/:id/:type?',
      name: 'inventory-summary',
      component: () => import('@/views/processSignOff/components/inventory-summary/inventory-summary.vue'),
      meta: { title: '盘点总结' },
      props: true
    },
    {
      path: 'shipment-confirmation/:id/:type?',
      name: 'shipment-confirmation',
      component: () => import('@/views/processSignOff/components/shipment-confirmation/shipment-confirmation.vue'),
      meta: { title: '发货确认' },
      props: true
    },
    {
      path: 'customer-changes/:id/:type?',
      name: 'customer-changes',
      component: () => import('@/views/processSignOff/components/customer-changes/customer-changes.vue'),
      meta: { title: '客户变更' },
      props: true
    },
    {
      path: 'ask-for-leave-to-go-out/:id/:type?',
      name: 'ask-for-leave-to-go-out',
      component: () => import('@/views/processSignOff/components/ask-for-leave-to-go-out/ask-for-leave-to-go-out.vue'),
      meta: { title: '请假外出' },
      props: true
    },
    {
      path: 'quit-detail/:id/:type?',
      name: 'quit-detail',
      component: () => import('@/views/processSignOff/components/apply-resignation/quit-detail.vue'),
      meta: { title: '申请离职' },
      props: true
    },
    {
      path: 'employment-confirmation-details/:id/:type?',
      name: 'employment-confirmation-details',
      component: () => import('@/views/processSignOff/components/employment-confirmation/employment-confirmation-details.vue'),
      meta: { title: '申请转正' },
      props: true
    },
    {
      path: 'apply-for-promotion/:id/:type?',
      name: 'apply-for-promotion',
      component: () => import('@/views/processSignOff/components/apply-for-promotion/apply-for-promotion.vue'),
      meta: { title: '申请升职' },
      props: true
    },
    {
      path: 'expense-reimbursement/:id/:type?',
      name: 'expense-reimbursement',
      component: () => import('@/views/processSignOff/components/reimbursement-and-allocation/expense-reimbursement.vue'),
      meta: { title: '报销/付款' },
      props: true
    },
    {
      path: 'allocation/:id/:type?',
      name: 'allocation',
      component: () => import('@/views/processSignOff/components/reimbursement-and-allocation/expense-reimbursement.vue'),
      meta: { title: '费用分摊' }, //  分摊
      props: true
    },
    {
      path: 'resell/:id/:type?',
      name: 'resell',
      component: () => import('@/views/processSignOff/components/resell/resell.vue'),
      meta: { title: '转卖收款' }, // 转卖
      props: true
    },
    {
      path: 'rent-payment/:id/:type?',
      name: 'rent-payment',
      component: () => import('@/views/processSignOff/components/rent-payment/rent-payment.vue'),
      meta: { title: '房租支付' },
      props: true
    },
    {
      path: 'article-borrowing/:id/:type?',
      name: 'article-borrowing',
      component: () => import('@/views/processSignOff/components/article-borrowing/article-borrowing.vue'),
      meta: { title: '物品借用' },
      props: true
    },
    {
      path: 'fund-purchase/:id/:type?',
      name: 'fund-purchase',
      component: () => import('@/views/processSignOff/components/fund-purchase/fund-purchase.vue'),
      meta: { title: '基金申购' },
      props: true
    },
    {
      path: 'fund-redemption/:id/:type?',
      name: 'fund-redemption',
      component: () => import('@/views/processSignOff/components/fund-redemption/fund-redemption.vue'),
      meta: { title: '基金赎回' },
      props: true
    },
    {
      path: 'new-store-financing/:id/:type?',
      name: 'new-store-financing',
      component: () => import('@/views/processSignOff/components/new-store-financing/new-store-financing.vue'),
      meta: { title: '新店筹资' },
      props: true
    },
    {
      path: 'operating-dividends/:id/:type?',
      name: 'operating-dividends',
      component: () => import('@/views/processSignOff/components/operating-dividends/operating-dividends.vue'),
      meta: { title: '门店分红' },
      props: true
    },
    {
      path: 'store-dividends/:id/:type?',
      name: 'store-dividends',
      component: () => import('@/views/processSignOff/components/store-dividends/store-dividends.vue'),
      meta: { title: '分红-旧股东' },
      props: true
    },
    {
      path: 'advances-to-employees/:id/:type?',
      name: 'advances-to-employees',
      component: () => import('@/views/processSignOff/components/advances-to-employees/advances-to-employees.vue'),
      meta: { title: '员工借支' },
      props: true
    },
    {
      path: 'inward-transfer-of-funds/:id/:type?',
      name: 'inward-transfer-of-funds',
      component: () => import('@/views/processSignOff/components/inward-transfer-of-funds/inward-transfer-of-funds.vue'),
      meta: { title: '资金内转' },
      props: true
    },
    {
      path: 'return-request/:id/:type?',
      name: 'return-request2',
      component: () => import('@/views/processSignOff/components/return-request/return-request.vue'),
      meta: { title: '申请退货' },
      props: true
    },
    {
      path: 'payment-for-goods/:id/:type?',
      name: 'payment-for-goods',
      component: () => import('@/views/processSignOff/components/payment-for-goods/payment-for-goods.vue'),
      meta: { title: '货款支付', rekeepAlive: true, scrollTop: 0, name: 'paymentForGoods' },
      props: true
    },
    {
      path: 'order-arr-details/:id',
      name: 'order-arr-details',
      component: () => import('@/views/processSignOff/components/payment-for-goods/order-arr-details.vue'),
      meta: { title: '原订单详情' },
      props: true
    },
    {
      path: 'payroll/:id/:type?',
      name: 'payroll',
      component: () => import('@/views/processSignOff/components/payroll/payroll.vue'),
      meta: { title: '工资发放' },
      props: true
    },
    {
      path: 'bonus-payment/:id/:type?',
      name: 'bonus-payment',
      component: () => import('@/views/processSignOff/components/bonus-payment/bonus-payment.vue'),
      meta: { title: '奖金发放' },
      props: true
    },
    {
      path: 'expense-refund/:id/:type?',
      name: 'expense-refund',
      component: () => import('@/views/processSignOff/components/expense-refund/expense-refund.vue'),
      meta: { title: '换货退款' },
      props: true
    },
    {
      path: 'apply-for-exchange/:id/:type?',
      name: 'apply-for-exchange',
      component: () => import('@/views/processSignOff/components/apply-for-exchange/apply-for-exchange.vue'),
      meta: { title: '申请换货' },
      props: true
    },
    {
      path: 'new-account-flow/:id/:type?',
      name: 'new-account-flow',
      component: () => import('@/views/processSignOff/components/new-account-flow/new-account-flow.vue'),
      meta: { title: '新增账户流水' },
      props: true
    },
    {
      path: 'resale-shipment/:id/:type?',
      name: 'resale-shipment',
      component: () => import('@/views/processSignOff/components/resale-shipment/resale-shipment.vue'),
      meta: { title: '转卖发货' },
      props: true
    },
    {
      path: 'redeposit/:id/:type?',
      name: 'redeposit',
      component: () => import('@/views/processSignOff/components/redeposit/redeposit.vue'),
      meta: { title: '转预存款' },
      props: true
    },
    {
      path: 'shipment-of-borrowed-goods/:id/:type?',
      name: 'shipment-of-borrowed-goods',
      component: () => import('@/views/processSignOff/components/shipment-of-borrowed-goods/shipment-of-borrowed-goods.vue'),
      meta: { title: '借货发货' },
      props: true
    },
    {
      path: 'return-of-borrowed-goods/:id/:type?',
      name: 'return-of-borrowed-goods',
      component: () => import('@/views/processSignOff/components/return-of-borrowed-goods/return-of-borrowed-goods.vue'),
      meta: { title: '还货' },
      props: true
    },
    {
      path: 'payment-by-the-company/:id/:type?',
      name: 'payment-by-the-company',
      component: () => import('@/views/processSignOff/components/payment-by-the-company/payment-by-the-company.vue'),
      meta: { title: '公司代付' },
      props: true
    },
    {
      path: 'employee-purchase/:id/:type?',
      name: 'employee-purchase',
      component: () => import('@/views/processSignOff/components/employee-purchase/employee-purchase.vue'),
      meta: { title: '员工自购' },
      props: true
    },
    {
      path: 'change-of-shares/:id/:type?',
      name: 'change-of-shares',
      component: () => import('@/views/processSignOff/components/change-of-shares/change-of-shares.vue'),
      meta: { title: '股份变更' },
      props: true
    },
    {
      path: 'change-service-staff/:id/:type?',
      name: 'change-service-staff',
      component: () => import('@/views/processSignOff/components/change-service-staff/change-service-staff.vue'),
      meta: { title: '更换服务员工' },
      props: true
    },
    {
      path: 'department-transfer/:id/:type?',
      name: 'department-transfer',
      component: () => import('@/views/processSignOff/components/change-service-staff/change-service-staff.vue'),
      meta: { title: '客户转移' },
      props: true
    },
    {
      path: 'assist-work-order/:id/:type?',
      name: 'assist-work-order',
      component: () => import('@/views/processSignOff/components/assist-work-order/assist-work-order.vue'),
      meta: { title: '协助工单' },
      props: true
    },
    {
      path: 'distributors-transfer-goods/:id/:type?',
      name: 'distributors-transfer-goods',
      component: () => import('@/views/processSignOff/components/distributors-transfer-goods/distributors-transfer-goods.vue'),
      meta: { title: '经销商调货' },
      props: true
    },
    {
      path: 'customer-service-recharge/:id/:type?',
      name: 'customer-service-recharge',
      component: () => import('@/views/processSignOff/components/customer-service-recharge/customer-service-recharge.vue'),
      meta: { title: '客户充值' },
      props: true
    },
    {
      path: 'cross-store-customer-purchase/:id/:type?',
      name: 'cross-store-customer-purchase',
      component: () => import('@/views/processSignOff/components/cross-store-customer-purchase/cross-store-customer-purchase.vue'),
      meta: { title: '跨店客户订单' },
      props: true
    },
    {
      path: 'proxy-settings/:id/:type?',
      name: 'off-proxy-settings',
      component: () => import('@/views/processSignOff/components/proxy-settings/proxy-settings.vue'),
      meta: { title: '申请代理' },
      props: true
    },
    {
      path: 'manufacturers-pre-deposit/:id/:type?',
      name: 'manufacturers-pre-deposit',
      component: () => import('@/views/processSignOff/components/manufacturers-pre-deposit/manufacturers-pre-deposit.vue'),
      meta: { title: '厂商预存款' },
      props: true
    },
    {
      path: 'seal-usage/:id/:type?',
      name: 'seal-usage',
      component: () => import('@/views/processSignOff/components/seal-usage/seal-usage.vue'),
      meta: { title: '印章使用' },
      props: true
    },
    {
      path: 'welfare-purchase/:id/:type?',
      name: 'off-welfare-purchase',
      component: () => import('@/views/processSignOff/components/welfare-purchase/welfare-purchase.vue'),
      meta: { title: '福利购' },
      props: true
    },
    {
      path: 'warehouse-transfer/:id/:type?',
      name: 'off-warehouse-transfer',
      component: () => import('@/views/processSignOff/components/warehouse-transfer/warehouse-transfer.vue'),
      meta: { title: '调库' },
      props: true
    },
    {
      path: 'apply-for-salary/:id/:type?',
      name: 'apply-for-salary',
      component: () => import('@/views/processSignOff/components/apply-for-salary/apply-for-salary.vue'),
      meta: { title: '申请工资', rekeepAlive: true, scrollTop: 0, name: 'ApplyForSalary' },
      props: true
    },
    {
      path: 'loan-repayment/:id/:type?',
      name: 'loan-repayment',
      component: () => import('@/views/processSignOff/components/loan-repayment/loan-repayment.vue'),
      meta: { title: '借支还款' },
      props: true
    },
    {
      path: 'asset-sale/:id/:type?',
      name: 'asset-sale',
      component: () => import('@/views/processSignOff/components/asset-sale/asset-sale.vue'),
      meta: { title: '资产转卖' },
      props: true
    },
    {
      path: 'asset-status-change/:id/:type?',
      name: 'asset-status-change',
      component: () => import('@/views/processSignOff/components/asset-status-change/asset-status-change.vue'),
      meta: { title: '资产状态变更' },
      props: true
    },
    {
      path: 'group-salary-distribution/:id/:type?',
      name: 'group-salary-distribution',
      component: () => import('@/views/processSignOff/components/group-salary-distribution/group-salary-distribution.vue'),
      meta: { title: '分组工资发放' },
      props: true
    },
    {
      path: 'cross-store-resale/:id/:type?',
      name: 'cross-store-resale',
      component: () => import('@/views/processSignOff/components/cross-store-resale/cross-store-resale.vue'),
      meta: { title: '跨店提货转卖' },
      props: true
    },
    {
      path: 'cross-store-pickup-and-resale/:id/:type?',
      name: 'cross-store-pickup-and-resale',
      component: () => import('@/views/processSignOff/components/cross-store-pickup-and-resale/cross-store-pickup-and-resale.vue'),
      meta: { title: '跨店提货借货' },
      props: true
    },
    {
      path: 'purchase-of-shares/:id/:type?',
      name: 'purchase-of-shares',
      component: () => import('@/views/processSignOff/components/purchase-of-shares/purchase-of-shares.vue'),
      meta: { title: '股份变更' },
      props: true
    },
    {
      path: 'apply-for-invoice/:id/:type?',
      name: 'apply-for-invoice',
      component: () => import('@/views/processSignOff/components/apply-for-invoice/apply-for-invoice.vue'),
      meta: { title: '申请发票' },
      props: true
    },
    {
      path: 'missing-shipment/:id/:type?',
      name: 'missing-shipment',
      component: () => import('@/views/processSignOff/components/missing-shipment/missing-shipment.vue'),
      meta: { title: '货件缺失' },
      props: true
    },
    {
      path: 'apply-for-job-transfer/:id/:type?',
      name: 'apply-for-job-transfer',
      component: () => import('@/views/processSignOff/components/apply-for-job-transfer/apply-for-job-transfer.vue'),
      meta: { title: '申请调岗' },
      props: true
    },
    {
      path: 'purchase-order/:id/:type?',
      name: 'purchase-order',
      component: () => import('@/views/processSignOff/components/purchase-order/purchase-order.vue'),
      meta: { title: '进货下单' },
      props: true
    },
    {
      path: 'meeting-room-reservation-reminder/:id/:type?',
      name: 'meeting-room-reservation-reminder',
      component: () => import('@/views/processSignOff/components/meeting-room-reservation-reminder/meeting-room-reservation-reminder.vue'),
      meta: { title: '会议室预定提醒' },
      props: true
    },
    {
      path: 'payment-confirmation/:id/:type?',
      name: 'payment-confirmation',
      component: () => import('@/views/processSignOff/components/payment-confirmation/payment-confirmation.vue'),
      meta: { title: '货款确认' },
      props: true
    }
  ]
}
]
