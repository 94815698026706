export default [
  // 订单列表
  {
    path: 'customer-recharge-return-payment/:id',
    name: 'customer-recharge-return-payment',
    component: () => import('@/views/sales-order/components/customer-recharge/recharge-and-voucher/customer-recharge-return-payment.vue'),
    meta: { title: '充值退款' },
    props: true
  },
  {
    path: 'combination-collection/:id/:orgId',
    name: 'combination-collection',
    component: () => import('@/views/sales-order/components/order-list/combination-collection.vue'),
    meta: { title: '组合回款' },
    props: true
  },
  {
    path: 'self-purchase-exchange/:id',
    name: 'self-purchase-exchange',
    component: () => import('@/views/sales-order/components/employee-self-purchase/self-purchase-list/self-purchase-exchange.vue'),
    meta: { title: '自购换货' },
    props: true
  },
  {
    path: 'details-customer-order/:id',
    name: 'details-customer-order',
    component: () => import('@/views/sales-order/components/order-list/details-customer-order.vue'),
    meta: { title: '客户订单详情' },
    props: true
  },
  {
    path: 'details-individual-customer-order/:id',
    name: 'details-individual-customer-order',
    component: () => import('@/views/sales-order/components/order-list/details-individual-customer-order.vue'),
    meta: { title: '散客订单详情' },
    props: true
  },
  {
    path: 'details-sales-exchange-goods/:id',
    name: 'details-sales-exchange-goods',
    component: () => import('@/views/sales-order/components/order-list/details-exchange-goods.vue'),
    meta: { title: '修改订单' },
    props: true
  },
  {
    path: 'details-self-payment/:id',
    name: 'details-self-payment',
    component: () => import('@/views/sales-order/components/employee-self-purchase/self-purchase-list/details-self-payment.vue'),
    meta: { title: '付款详情' },
    props: true
  },
  {
    path: 'details-self-receipt/:id/:type?',
    name: 'details-self-receipt',
    component: () => import('@/views/sales-order/components/employee-self-purchase/self-purchase-list/details-self-receipt.vue'),
    meta: { title: '收货详情' },
    props: true
  },
  {
    path: 'recharge-money',
    name: 'recharge-money',
    component: () => import('@/views/sales-order/components/customer-recharge/recharge-money.vue'),
    meta: { title: '充值', rekeepAlive: true, scrollTop: 0, name: 'rechargeMoney' },
    props: true
  },
  {
    path: 'details-customer-recharge/:id/:type/:pointsType?',
    name: 'details-customer-recharge',
    component: () => import('@/views/sales-order/components/customer-recharge/details-customer-recharge.vue'),
    meta: { title: '代金券使用明细' },
    props: true
  },
  // // 懒人包列表
  {
    path: 'details-lazy/:id',
    name: 'details-lazy',
    component: () => import('@/views/sales-order/components/order-list/lazy-list/details-lazy.vue'),
    meta: { title: '详情' },
    props: true
  },
  {
    path: 'edit-lazy/:id?',
    name: 'edit-lazy',
    component: () => import('@/views/sales-order/components/order-list/lazy-list/edit-lazy.vue'),
    meta: { title: '新增/编辑' },
    props: true
  },
  // 员工自购
  // // 购物清单
  {
    path: 'details-shopping/:id/:signId',
    name: 'details-shopping',
    component: () => import('@/views/sales-order/components/employee-self-purchase/shopping-list/details-shopping.vue'),
    meta: { title: '福利购' },
    props: true
  }
]
