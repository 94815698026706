export default [
  // 制度公告
  {
    path: 'details-submit-data-collection/:id/:orgId',
    name: 'details-submit-data-collection',
    component: () => import('@/views/message-management/components/data-collection/detail-submit-data-collection.vue'),
    meta: { title: '部门提交详情' }, // 编辑公告
    props: true
  },
  {
    path: 'edit-submit-data-collection/:id/:orgId',
    name: 'edit-submit-data-collection',
    component: () => import('@/views/message-management/components/data-collection/edit-submit-data-collection.vue'),
    meta: { title: '编辑部门提交' }, // 编辑公告
    props: true
  },
  {
    path: 'edit-submit-survey-data/:id',
    name: 'edit-submit-survey-data',
    component: () => import('@/views/message-management/components/data-collection/edit-submit-data-collection.vue'),
    meta: { title: '编辑部门提交' }, // 编辑公告
    props: true
  },
  {
    path: 'submit-data-collection/:id',
    name: 'submit-data-collection',
    component: () => import('@/views/message-management/components/data-collection/submit-data-collection.vue'),
    meta: { title: '数据提交' }, // 编辑公告
    props: true
  },
  {
    path: 'details-data-collection/:id',
    name: 'details-data-collection',
    component: () => import('@/views/message-management/components/data-collection/details-data-collection.vue'),
    meta: { title: '数据详情' }, // 编辑公告
    props: true
  },
  {
    path: 'details-survey-data/:id',
    name: 'details-survey-data',
    component: () => import('@/views/message-management/components/data-collection/details-data-collection.vue'),
    meta: { title: '数据详情' }, // 编辑公告
    props: true
  },
  {
    path: 'edit-data-collection/:id?',
    name: 'edit-data-collection',
    component: () => import('@/views/message-management/components/data-collection/edit-data-collection.vue'),
    meta: { title: '编辑/新增' }, // 编辑公告
    props: true
  },
  {
    path: 'edit-survey-data/:id?',
    name: 'edit-survey-data',
    component: () => import('@/views/message-management/components/data-collection/edit-data-collection.vue'),
    meta: { title: '编辑/新增' }, // 编辑公告
    props: true
  },
  {
    path: 'edit-system-announcement/:id?',
    name: 'edit-system-announcement',
    component: () => import('@/views/message-management/components/system-announcement/system-announcement/edit-system-announcement.vue'),
    meta: { title: '编辑' }, // 编辑公告
    props: true
  },
  // // 文章栏目
  {
    path: 'edit-article-column/:id?',
    name: 'edit-article-column',
    component: () => import('@/views/message-management/components/system-announcement/article-column/edit-article-column.vue'),
    meta: { title: '编辑' }, // 编辑栏目
    props: true
  },
  {
    path: 'details-message/:id',
    name: 'details-message',
    component: () => import('@/views/message-management/components/message-list/details-message.vue'),
    meta: { title: '消息详情', rekeepAlive: true, scrollTop: 0, name: 'detailsMessage' },
    props: true
  },
  {
    path: 'message-list',
    name: 'message-list',
    component: () => import('@/views/message-management/message-list.vue'),
    meta: { title: '消息列表', rekeepAlive: true, scrollTop: 0, name: 'messageList' },
    props: true
  },
  {
    path: 'details-assist-work-order/:id/:signId',
    name: 'details-assist-work-order',
    component: () => import('@/views/message-management/components/assist-work-order/details-assist-work-order.vue'),
    meta: { title: '工单详情' },
    props: true
  },
  {
    path: 'details-baiwen-baida',
    name: 'details-baiwen-baida',
    component: () => import('@/views/home/components/home-page/baiwen-baida/details-baiwen-baida.vue'),
    meta: { title: '问题详情' }, // 首页 与 我的 百问百答详情
    props: true
  },
  // 知识题库
  {
    path: 'details-question-baiwen-baida',
    name: 'details-question-baiwen-baida',
    component: () => import('@/views/message-management/components/knowledge-question-bank/baiwen-baida/details-baiwen-baida.vue'),
    meta: { title: '问题详情' }, // 消息列表 百问百答详情
    props: true
  },
  {
    path: 'baiwen-baida/:top',
    name: 'baiwen-baida',
    component: () => import('@/views/home/components/home-page/baiwen-baida/index.vue'),
    meta: { title: '百问百答', rekeepAlive: true, scrollTop: 0, name: 'baiwenBaida' },
    props: true
  },
  {
    path: 'edit-question-management/:id?/:subjectId?',
    name: 'edit-question-management',
    component: () => import('@/views/message-management/components/knowledge-question-bank/question-management/edit-question-management.vue'),
    meta: { title: '新建/编辑' }, // 题目管理
    props: true
  },
  {
    path: 'details-subject-test/:id',
    name: 'details-subject-test',
    component: () => import('@/views/message-management/components/knowledge-question-bank/subject-test/details-subject-test.vue'),
    meta: { title: '试卷' }, // 科目考试
    props: true
  },
  // // 考试记录
  {
    path: 'details-exam-records/:id',
    name: 'details-exam-records',
    component: () => import('@/views/message-management/components/knowledge-question-bank/exam-records/details-exam-records.vue'),
    meta: { title: '考试详情' }, // 考试记录详情
    props: true
  },
  {
    path: 'details-exam-records-analysis/:id',
    name: 'details-exam-records-analysis',
    component: () => import('@/views/message-management/components/knowledge-question-bank/exam-records/details-exam-records-analysis.vue'),
    meta: { title: '考试解析' }, // 考试记录详情带解析
    props: true
  },
  // // 管理题库
  {
    path: 'details-analysis/:id',
    name: 'details-analysis',
    component: () => import('@/views/message-management/components/knowledge-question-bank/manage-question-banks/details-analysis.vue'),
    meta: { title: '题库解析设置' }, // 管理题库
    props: true
  }
]
