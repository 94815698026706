import Homelayout from '@/views/home' // 主页面结构

export default [{
  path: '/system',
  component: Homelayout,
  children: [
    // {
    //   path: 'bind-weChat',
    //   name: 'bindWeChat',
    //   component: () => import('@/views/system/bind-weChat.vue'),
    //   meta: { title: '微信绑定' }
    // },
    {
      path: 'personal-weChat-code',
      name: 'personal-weChat-code',
      component: () => import('@/views/system/personal-weChat-code.vue'),
      meta: { title: '个人微信码' }
    },
    {
      path: 'bind-weChat-list',
      name: 'bind-weChat-list',
      component: () => import('@/views/system/bind-weChat-list.vue'),
      meta: { title: '微信绑定' }
    },
    {
      path: 'receivingAddress',
      name: 'receivingAddress',
      component: () => import('@/views/system/receiving-address.vue'),
      meta: { title: '收货地址' }
    },
    {
      path: 'personal',
      name: 'personal',
      component: () => import('@/views/system/personal.vue'),
      meta: { title: '个人资料', keepAlive: true, scrollTop: 0, name: 'personal' },
      props: true
    },
    {
      path: 'collection-account-number',
      name: 'collection-account-number',
      component: () => import('@/views/system/collection-account-number.vue'),
      meta: { title: '收款账户' }
    },
    {
      path: 'invoicing-information',
      name: 'invoicing-information',
      component: () => import('@/views/system/invoicing-information.vue'),
      meta: { title: '发票信息' }
    },
    {
      path: 'group',
      name: 'group',
      component: () => import('@/views/system/group.vue'),
      meta: { title: '集团管理', keepAlive: true, scrollTop: 0, name: 'group' }
    },
    {
      path: 'role-settings',
      name: 'role-settings',
      component: () => import('@/views/system/role-settings.vue'),
      meta: { title: '角色设置' }
    },
    {
      path: 'product-unit',
      name: 'product-unit',
      component: () => import('@/views/system/product-unit.vue'),
      meta: { title: '单位设置' }
    },
    {
      path: 'password-reset',
      name: 'password-reset',
      component: () => import('@/views/system/password-reset.vue'),
      meta: { title: '密码重置' }
    }
  ]
}]
