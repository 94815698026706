<template>
  <div id="app">
    <!-- 动态缓存 -->
    <div>
      <keep-alive :include="clacInclude">
        <router-view></router-view>
      </keep-alive>
    </div>
    <Loading v-show="LOADING" :value="value"></Loading>
    <!-- 弹出最新公告 -->
    <div id="T_latest_announcement"></div>
    <van-dialog v-model="passwordShow" title="修改密码" :before-close="beforeClose" show-cancel-button>
      <p class="forgetpwd">当前密码以过期，请修改密码</p>
      <OverduePassword ref="OverduePassword" />
      <p class="forgetpwd" v-if="cencel">不修改密码将直接退出，请再次点击取消</p>
    </van-dialog>
    <!-- 预留几个位置方便放全局遮罩层 -->
    <div class="zIndex99" id="T_overlay_1"></div>
    <div class="zIndex99" id="T_overlay_2"></div>
    <div class="zIndex99" id="T_overlay_3"></div>
    <!-- 产品遮罩层 -->
    <div id="get_container"></div>
    <div id="login_QRCode"></div>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import OverduePassword from '@/views/system/components/personal/overdue-password.vue'
export default {
  name: 'App',
  data: () => ({
    include: [],
    value: '',
    value2: '',
    scrollTopNum: 0,
    passwordShow: false,
    cencel: false,
    testUser: ['xlw', 'xlz', 'dhb', 'hzj', '541'] // 测试账号
  }),
  computed: {
    LOADING () {
      return this.$store.state.loading.LOADING
    },
    clacInclude () {
      return this.$store.state.dynamicCache.appIncludes
    }
  },
  watch: {
    LOADING () {
      this.value = this.$store.state.loading.value
    },
    '$store.state.user.passwordStatus': {
      immediate: true,
      handler (val) {
        this.passwordShow = val
      }
    },
    '$store.state.user.userInfo': { // 测试 规定个别账号显示控制台
      immediate: true,
      handler (val) {
        if (this.testUser.includes(val.userName)) {
          // eslint-disable-next-line no-unused-vars, no-undef
          // const vConsole = new VConsole()
        }
      }
    },
    $route (to, from) {
      if (to.meta.rekeepAlive) { // 返回页 获取长时间需要缓存组件 并 添加进缓存序列中
        this.$store.dispatch('dynamicCache/setReturnKeepAlive', to.meta.name)
      }
    }
  },
  created () {
    // eslint-disable-next-line no-unused-vars, no-undef
    // const vConsole = new VConsole()
    // 手指接触屏幕
  },
  methods: {
    beforeClose (action, done) {
      if (action === 'confirm') { // 点击确定走这里
        this.$refs.OverduePassword.$refs.forgetpwdmine.click()
        return done(false)
      } else { // 点击取消走这里
        if (this.cencel) {
          this.cencel = false
          this.passwordShow = false
          this.$store.dispatch('user/setPasswordStatus', false)
          this.$store.dispatch('user/cleanupData') // 清理vuex中的数据
          this.$router.push('/login')
          window.location.reload()
          return done(true) // 关闭弹窗, true可以省略
        } else {
          this.cencel = true
          return done(false) // 关闭弹窗, true可以省略
        }
      }
    }
  },
  components: {
    Loading,
    OverduePassword
  }
}
</script>

<style lang="less" scoped>
.forgetpwd{
  text-align: center;
  font-size: 14px;
  padding:10px 0;
  color: rgb(255, 154, 154);
}
.zIndex99{
  position: relative;
  z-index: 99;
}
</style>
