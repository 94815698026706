export default [
  // 财务业绩
  {
    path: 'new-details-group-salary/:id/:type',
    name: 'new-details-group-salary',
    component: () => import('@/views/financial-management/components/new-salary-management/new-group-salary/new-details-group-salary.vue'),
    meta: { title: '新分组工资详情', rekeepAlive: true, scrollTop: 0, name: 'newDetailsGroupSalary' }, // 分组发放 | 历史
    props: true
  },
  {
    path: 'new-departmental-salary-details/:orgId/:type',
    name: 'new-departmental-salary-details',
    component: () => import('@/views/financial-management/components/new-salary-management/new-departmental-salary-details.vue'),
    meta: { title: '新部门工资详情', rekeepAlive: true, scrollTop: 0, name: 'editNewDetailsEmployeeSalaries' },
    props: true
  },
  {
    path: 'edit-new-details-employee-salaries/:userId',
    name: 'editNewDetailsEmployeeSalaries',
    component: () => import('@/views/financial-management/components/new-salary-management/edit-new-details-employee-salaries.vue'),
    meta: { title: '工资编辑', rekeepAlive: true, scrollTop: 0, name: 'editNewDetailsEmployeeSalaries' },
    props: true
  },
  {
    path: 'details-financial-performance',
    name: 'details-financial-performance',
    component: () => import('@/views/financial-management/components/performance-management/details-performance.vue'),
    meta: { title: '业绩详情', rekeepAlive: true, scrollTop: 0, name: 'detailsFinancialPerformance' },
    props: true
  },
  {
    path: 'details-customer-financial-performance/:type?',
    name: 'details-customer-financial-performance',
    component: () => import('@/views/financial-management/components/performance-management/details-customer-performance.vue'),
    meta: { title: '客户回款', rekeepAlive: true, scrollTop: 0, name: 'detailsCustomerFinancialPerformance' },
    props: true
  },
  {
    path: 'details-type-financial-performance',
    name: 'details-type-financial-performance',
    component: () => import('@/views/financial-management/components/performance-management/details-type-performance.vue'),
    meta: { title: '业绩类型回款' },
    props: true
  },
  // // 提货业绩排名
  {
    path: 'details-ranking-of-delivery-performance/:id?',
    name: 'details-ranking-of-delivery-performance',
    component: () => import('@/views/financial-management/components/performance-management/ranking-of-delivery-performance/details-ranking-of-delivery-performance.vue'),
    meta: { title: '提货业绩详情', rekeepAlive: true, scrollTop: 0, name: 'detailsRankingOfDeliveryPerformance' },
    props: true
  },
  // // 门店提货业绩
  {
    path: 'details-store-pickup-performance/:id',
    name: 'details-store-pickup-performance',
    component: () => import('@/views/financial-management/components/performance-management/store-pickup-performance/details-store-pickup-performance.vue'),
    meta: { title: '个人提货业绩排名', rekeepAlive: true, scrollTop: 0, name: 'detailsStorePickupPerformance' },
    props: true
  },
  // 存货风险
  // // 门店风险
  {
    path: 'inventory-risk/:id',
    name: 'store-details-inventory-risk',
    component: () => import('@/views/financial-management/components/inventory-risk/store-risk/inventory-risk.vue'),
    meta: { title: '存货风险', rekeepAlive: true, scrollTop: 0, name: 'storeDetailsInventoryRisk' },
    props: true
  },
  {
    path: 'financial-details-inventory-risk/:id/:date/:start/:end/:custId?',
    name: 'financial-details-inventory-risk',
    component: () => import('@/views/financial-management/components/inventory-risk/store-risk/details-inventory-risk.vue'),
    meta: { title: '风险详细', rekeepAlive: true, scrollTop: 0, name: 'financialFetailsInventoryRisk' },
    props: true
  },
  {
    path: 'financial-details-risk-order/:id',
    name: 'financial-details-risk-order',
    component: () => import('@/views/financial-management/components/inventory-risk/store-risk/details-risk-order.vue'),
    meta: { title: '订单详情' },
    props: true
  },
  {
    path: 'financial-details-inventory-products/:id/:date',
    name: 'financial-details-inventory-products',
    component: () => import('@/views/financial-management/components/inventory-risk/store-risk/details-inventory-products.vue'),
    meta: { title: '存货产品明细' },
    props: true
  },
  // // 客户欠款
  {
    path: 'customer-arrears-order/:id',
    name: 'customer-arrears-order',
    component: () => import('@/views/financial-management/components/inventory-risk/customer-arrears/customer-arrears-order.vue'),
    meta: { title: '欠款详情', rekeepAlive: true, scrollTop: 0, name: 'customerArrearsOrder' },
    props: true
  },
  // 基金理财
  {
    path: 'details-subscription-redemption/:id',
    name: 'details-subscription-redemption',
    component: () => import('@/views/financial-management/components/fund-financing/subscription-redemption/details-subscription-redemption.vue'),
    meta: { title: '申购详情' },
    props: true
  },
  // 员工借支
  {
    path: 'details-debit-list/:id/:type',
    name: 'details-debit-list',
    component: () => import('@/views/financial-management/components/advances-to-employees/debit-list/details-debit-list.vue'),
    meta: { title: '借支详情' },
    props: true
  },
  {
    path: 'details-payment-by-the-company/:id',
    name: 'details-payment-by-the-company',
    component: () => import('@/views/financial-management/components/advances-to-employees/payment-by-the-company/details-payment-by-the-company.vue'),
    meta: { title: '代付详情' },
    props: true
  },
  // 运营分析
  // // 利润分析
  {
    path: 'details-profit-analysis/:id/:date',
    name: 'details-profit-analysis',
    component: () => import('@/views/financial-management/components/profit-analysis/profit-analysis/details-profit-analysis.vue'),
    meta: { title: '股东列表' },
    props: true
  },
  {
    path: 'details-custom-in-out/:id/:date/:type',
    name: 'details-custom-in-out',
    component: () => import('@/views/financial-management/components/profit-analysis/profit-analysis/details-custom-in-out.vue'),
    meta: { title: '客户转入' },
    props: true
  },
  // // 分成设置
  {
    path: 'edit-split-settings/:id?',
    name: 'edit-split-settings',
    component: () => import('@/views/financial-management/components/profit-analysis/split-settings/edit-split-settings.vue'),
    meta: { title: '添加/编辑设置' },
    props: true
  },
  // 工资管理
  // // 工资设置details-employee-salaries
  {
    path: 'details-salary-setting/:id',
    name: 'details-salary-setting',
    component: () => import('@/views/financial-management/components/salary-management/salary-setting/details-salary-setting.vue'),
    meta: { title: '详情' }, // 工资设置的详情
    props: true
  },
  {
    path: 'edit-salary-setting/:id?',
    name: 'edit-salary-setting',
    component: () => import('@/views/financial-management/components/salary-management/salary-setting/edit-salary-setting.vue'),
    meta: { title: '添加/编辑' }, // 工资设置的编辑
    props: true
  },
  // // 员工工资
  {
    path: 'details-employee-salaries/:id',
    name: 'details-employee-salaries',
    component: () => import('@/views/financial-management/components/salary-management/employee-salaries/details-employee-salaries.vue'),
    meta: { title: '详情' }, // 员工工资详情
    props: true
  },
  {
    path: 'detailed-employee-salaries/:id/:type',
    name: 'detailed-employee-salaries',
    component: () => import('@/views/financial-management/components/salary-management/employee-salaries/detailed-employee-salaries.vue'),
    meta: { title: '明细' }, // 员工工资详情
    props: true
  },
  // // 部门工资
  {
    path: 'details-org-salaries/:id',
    name: 'details-org-salaries',
    component: () => import('@/views/financial-management/components/salary-management/org-salaries/details-org-salaries.vue'),
    meta: { title: '详情' }, // 部门工资详情
    props: true
  },
  // // 工资汇总
  {
    path: 'details-salary-summary/:id',
    name: 'details-salary-summary',
    component: () => import('@/views/financial-management/components/salary-management/salary-summary/details-salary-summary.vue'),
    meta: { title: '部门工资详情', rekeepAlive: true, scrollTop: 0, name: 'detailsSalarySummary' }, // 部门工资明细详情
    props: true
  },
  {
    path: 'details-salary-summary-flow/:id',
    name: 'details-salary-summary-flow',
    component: () => import('@/views/financial-management/components/salary-management/salary-summary/details-salary-summary-flow.vue'),
    meta: { title: '工资汇总详情' }, // 工资汇总详情
    props: true
  },
  // // 分组发放
  {
    path: 'code-group-salary/:id',
    name: 'code-group-salary',
    component: () => import('@/views/financial-management/components/salary-management/group-salary/code-group-salary.vue'),
    meta: { title: '授权' },
    props: true
  },
  {
    path: 'asso-group-salary/:id',
    name: 'asso-group-salary',
    component: () => import('@/views/financial-management/components/salary-management/group-salary/asso-group-salary.vue'),
    meta: { title: '关联' },
    props: true
  },
  {
    path: 'details-group-salary/:id/:type',
    name: 'details-group-salary',
    component: () => import('@/views/financial-management/components/salary-management/group-salary/details-group-salary.vue'),
    meta: { title: '分组工资详情', rekeepAlive: true, scrollTop: 0, name: 'detailsGroupSalary' }, // 分组发放 | 历史
    props: true
  },
  {
    path: 'flow-details-group-salary/:id/:signId/:type',
    name: 'flow-details-group-salary',
    component: () => import('@/views/financial-management/components/salary-management/group-salary/flow-details-group-salary.vue'),
    meta: { title: '分组工资发放流程' }, // 分组发放 | 历史
    props: true
  },
  // 销售订单
  // // 超提订单
  {
    path: 'details-overdelivery-order/:id',
    name: 'details-overdelivery-order',
    component: () => import('@/views/financial-management/components/sales-order-management/overdelivery-order/details-overdelivery-order.vue'),
    meta: { title: '详情' },
    props: true
  },
  // 直营小金库
  // // 小金库流水
  {
    path: 'details-treasury-flowing/:id/:signId',
    name: 'details-treasury-flowing',
    component: () => import('@/views/financial-management/components/direct-operated-small-treasury/treasury-flowing/details-treasury-flowing.vue'),
    meta: { title: '详情' },
    props: true
  },
  // 收款账户
  // // 账户列表
  {
    path: 'financial-edit-account-list/:id?',
    name: 'financial-edit-account-list',
    component: () => import('@/views/financial-management/components/collection-account/edit-account-list.vue'),
    meta: { title: '新增/编辑' },
    props: true
  },
  // // 账户流水
  {
    path: 'financial-edit-account-flow/:id?',
    name: 'financial-edit-account-flow',
    component: () => import('@/views/financial-management/components/collection-account/edit-account-flow.vue'),
    meta: { title: '新增/编辑' },
    props: true
  },
  {
    path: 'risk-summary/:id/:dateId',
    name: 'riskSummary',
    component: () => import('@/views/financial-management/components/inventory-risk/risk-summary/index.vue'),
    meta: { title: '风险汇总', rekeepAlive: true, scrollTop: 0, name: 'riskSummary' },
    props: true
  },
  {
    path: 'customer-risk-summary/:id/:dateId/:userId?',
    name: 'customerRiskSummary',
    component: () => import('@/views/financial-management/components/inventory-risk/risk-summary/customer-risk-summary.vue'),
    meta: { title: '员工风险汇总详细', rekeepAlive: true, scrollTop: 0, name: 'customerRiskSummary' },
    props: true
  }
]
