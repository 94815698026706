export const Induction = {
  // 移动端
  customerType: [
    { text: '城镇户口' },
    { text: '农村户口' }
  ],
  educationType: [
    { text: '研究生' },
    { text: '本科' },
    { text: '大专' },
    { text: '中专' },
    { text: '高中' },
    { text: '初中' },
    { text: '其他' }
  ],
  academicType: [
    { text: '学士' },
    { text: '硕士' },
    { text: '博士' },
    { text: '其它' }
  ],
  warehouseType: [
    { text: '虚拟' },
    { text: '实体' }
  ],
  paymentMethod: [
    { text: '银行卡' },
    { text: '收款码' }
  ],
  classification: [
    { text: '公告' },
    { text: '制度' },
    { text: '经销' },
    { text: '店长' },
    { text: '经理' },
    { text: '首页' }
  ]
}

// 公告类型颜色
export const classificationTypeOf = {
  公告: '#ff976a',
  制度: '#ff5959',
  经销: '#07c160',
  店长: '#ffd06a',
  经理: '#9765ee'
}

// 申购产品
export const subscriptionproducts = [
  { text: '理财产品(低风险)' },
  { text: '股票基金(高风险)' }
]
// 基金赎回用申购产品
export const subscriptionproducts2 = [
  { text: '理财产品(低风险)' },
  { text: '股票基金(高风险)' },
  { text: '利息收益赎回' }
]

// 合同类型
export const contractTypes = [
  { text: '劳动合同' },
  { text: '劳务合同' },
  { text: '实习协议' },
  { text: '未签合同' }
]

// 收钱吧 - 零售
export const collectMoneyMode = [
  { text: 'POS机' },
  { text: '扫码收' },
  { text: '收款码' },
  { text: '线下收' }
]

// 富友 - 零售
export const richFriendMode = [
  { text: 'POS机-微信付', value: 'WECHAT' },
  { text: 'POS机-支付宝', value: 'ALIPAY' },
  { text: 'POS机-银联付', value: 'UNIONPAY' },
  { text: 'POS机-刷卡付', value: 'CARD' },
  { text: '扫码收-微信付', value: 'WECHAT' },
  { text: '扫码收-支付宝', value: 'ALIPAY' },
  { text: '扫码收-银联付', value: 'UNIONPAY' },
  { text: '收款码-微信码', value: 'WECHAT' },
  { text: '收款码-支付宝', value: 'ALIPAY' },
  { text: '收款码-银联码', value: 'UNIONPAY' },
  { text: '线下收' }
]

// 两个收款方式都没有 - 零售
export const offlineCollection = [
  { text: '线下收' }
]

// 提货方式
export const deliveryMethod = [
  { text: '一次提货', value: '1' },
  { text: '分批提货', value: '0' }
]

// 是否有业绩
export const achievement = [
  { text: '是', value: '1' },
  { text: '否', value: '0' }
]

// 审核
export const orderStatusList = [
  { text: '未审核', value: '1' },
  { text: '已审核', value: '2' }
]
// 付款
export const payStatusList = [
  { text: '未付款', value: '0' },
  { text: '已付款', value: '1' }
]
// 收货
export const storeFinishList = [
  { text: '未收货', value: '0' },
  { text: '部分收货', value: '2' },
  { text: '已收货', value: '1' }
]

// 收货
export const preset = [
  { name: '100元', value: '100' },
  { name: '200元', value: '200' },
  { name: '300元', value: '300' },
  { name: '500元', value: '500' },
  { name: '800元', value: '800' },
  { name: '1000元', value: '1000' }
]

// export const upload = {
//   url: process.env.VUE_APP_BASE_API + '/file/upload',
//   accept: '.png,.jpg,jpeg'
// }
export const bpayType = [
  {
    text: '所有产品',
    idType: 1
  },
  {
    text: '指定产品',
    idType: 2
  },
  {
    text: '指定数量',
    idType: 3
  }
]

// 门店分红流程的分红分类
export const dividendCategory = [
  { text: '共同分红', value: '4' },
  { text: '股东分红', value: '3' },
  { text: '经营分成', value: '2' }
]

// 客户充值类型
export const rechargeType = [
  { text: '现金', value: '1' },
  { text: '积分', value: '2' }
]

// 代金券使用类型
export const voucherUseType = [
  { text: '单次使用', value: '0' },
  { text: '合并使用', value: '1' }
]

// 印章类型
export const sealType = [
  { text: '公章' },
  { text: '合同章' },
  { text: '财务章' },
  { text: '发票章' },
  { text: '法人章' }
]

// 订单管理优惠过大
export const preferential = [
  { text: '7.5折', value: '75' },
  { text: '5.5折', value: '55' },
  { text: '3.5折', value: '35' }
]

// 知识题库选项
export const topicOptions = [
  { text: 'A', value: 'A' },
  { text: 'B', value: 'B' },
  { text: 'C', value: 'C' },
  { text: 'D', value: 'D' },
  { text: 'E', value: 'E' }
]

// 选项循环
export const topicOptionsArr = ['A', 'B', 'C', 'D', 'E']

// 知识题库题目类型
export const topicClass = [
  { text: '是非题', value: '1', type: '1', disabled: false },
  { text: '单选题', value: '2', type: '2', disabled: false },
  { text: '多选题', value: '3', type: '2', disabled: false }
]

// 栏目类型
export const columnType = [
  { text: '详情', value: 1 },
  { text: '列表', value: 2 }
]

/**
 * 少发原因
 */

export const reasonsGorLessOccurrence = [
  { value: '1', text: '损坏', value1: 'A' },
  { value: '2', text: '少发', value1: 'B' }
]

/**
 * 徐华 收款方式
 */
export const xuHuaPayType = [
  { value: '现金', text: '现金' },
  { value: '微信', text: '微信' },
  { value: '刷卡', text: '刷卡' },
  { value: '支付宝', text: '支付宝' }
]

/**
 * 微信公众号白名单
 */
export const WXwhiteList = ['groupAnnouncement', 'newDetailsNoticeSystem']

/**
 * 数据收集类型 1文本 2数字 3时间 4下拉框
 */
export const dataCollectionType = [
  { value: '1', text: '文本' },
  { value: '2', text: '数字' },
  { value: '3', text: '时间' },
  { value: '4', text: '下拉框' }
]

/**
 * 培训状态
 */
export const trainingType = [
  { value: '1', text: '培训' },
  { value: '0', text: '未培训' }
]
/**
 * 权限
 */
export const definitionType = [
  { value: '1', label: '人事权限' },
  { value: '2', label: '财务权限' },
  { value: '3', label: '共同权限' }
]

/**
 * 工资标题类型
 */
export const salaryTitleType = [
  { value: '1', label: '不统计' },
  { value: '2', label: '增加项' },
  { value: '3', label: '扣除项' },
  { value: '4', label: '说明项' }
]
